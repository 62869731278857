/* .section__padding{
    padding: 4rem 4rem;
} */
.footer{
    /* background-color: #2d2d32; */
    background-color: #550A35;
    /* background-color: #36013f; */

 /* background-color: #3d0c02; */
 /* background-color: #4b0150; */
 /* background-color: #583759; */
    margin-top: 10vh;

}
.sb__footer{
    font-display: flex;
    flex-direction: column;
}
.sb__footer-links{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;

}
.sb__footer-links_div{
    width: 150px;
    margin: 1rem;
    display:flex;
    justify-content: flex-start;
    flex-direction: column;
    color:white;
 

}
.sb__footer-links_div_img{
    width: 150px;
    margin-top : 2.5rem;
    display:flex;
    justify-content: flex-start;
    flex-direction: column;
    color:white;

}

a{
    color: rgb(175,175,179);
    text-decoration: none;

}
.socialmedia{
    display:flex;
    flex-direction: row;
    gap: 10px;
}
.sb__footer-links_div h4{
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
}
.sb__footer-links_div p{
    font: 12px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
    
}
.sb__footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}
.sb__footer-below-links{
    display: flex;
    flex-direction: row;
    

}

.sb__footer-below-links p{
   font-size: 13px;
   line-height: 15px;
   margin-left: 2rem;
   color: white;
   font-weight: 600;

}

hr{
    color: white !important;
    width: 100%;

}

.sb__footer-copyright p{
    font-family: 13px;
    line-height: 15px;
    color: rgb(255,255,255);
    font-weight: 600;

}
@media screen and(max-width:850px){
    .sb__footer-heading h1{
        font-size: 44px;
        line-height: 50px;

    }
}
@media screen  and (max-width:550px){
    .sb__footer-heading h1{
        font-size: 34px;
        line-height: 42px;
        
    }
    .sb__footer-links div {
        margin:1rem 0;

    }
    .sb__footer-btn p{
        font-size: 14px;
        line-height: 20px;
    }
    .sb__footer-below{
        flex-direction: column;
        justify-content: left;
    }
    .sb__footer-below-links{
        flex-direction: column;
    }
    .sb__footer-below-links p{
        margin-left: 0rem;
        margin-top: 1rem;
    }
    
}
@media screen and (max-width:400px){
    .sb__footer-heading h1{
        font-size: 27px;
        line-height: 38px;

    }
    
}






























