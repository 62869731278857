body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.contact-page {
    /* background-color: #d3c1b7; */
    background-color: white;

    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
   
    height:auto;
}

.contact-container {
    background-color: #f2f2f2;
    width: 90%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.contact-form, .contact-info {
    padding: 20px;
}

.contact-form {
    background-color: #ffffff;
    flex: 1;
    padding: 40px;
    box-sizing: border-box;
}

.contact-form h2 {
    margin-bottom: 20px;
}

.contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact-form textarea {
    height: 100px;
}

.contact-form button {
   
    background-color: black;
    
    color: #ffc400;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
}

.contact-info {
    background-color: #222222;
    color: white;
  
    flex: 1;
}

.contact-info h2 {
    margin-bottom: 20px;
}

.contact-info p {
    margin: 10px 0;
}

.contact-info i {
    margin-right: 10px;
}

.social-icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    
}

.social-icons i {
    margin-right: 15px;
    cursor: pointer;
    /* &:hover{color: yellow;} */
    
} 
.sb__footer-links_div_img{
    margin-left: 10vh;
}
@media (min-width: 600px) {
    .contact-container {
        flex-direction: row;
    }
}
@media (min-width: 600px) {
    .sb__footer-links_div_img{
        margin-left: 10vh;
       
    }
}


/* @media (min-width: 600px) {
    .sb__footer-links_div_img{
        flex-direction: row;
        padding-left: 100rem;
    }
} */





