.launch-page * {
  margin: 0;
  padding: 0;
  font-family: 'Oswald', sans-serif;
}

.launch-page {
  overflow: hidden;
}

.launch-page .coming-soon {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fece00;
  overflow: hidden;
}

.launch-page .coming-soon-inner {
  position: relative;
}

.launch-page .heading {
  font-size: 100px;
  text-transform: uppercase;
  padding: 20px;
  background: #000;
  color: #fece00;
  transform: skewY(-10deg);
  font-weight: 500;
  line-height: 100px;
}
.launch-page .heading1 {
  padding: 20px;
  color: #fece00;
  transform: skewY(-10deg);
  font-weight: 500;
  line-height: 100px;
 margin-top: 35vh;
 
}


.launch-page .small-heading {
  font-size: 30px;
  text-transform: uppercase;
  display: inline-block;
  padding: 20px;
  background: #fff;
  color: #000;
  transform: skewY(-10deg);
  font-weight: 500;
  line-height: 30px;
  box-shadow: 10px 10px 20px rgb(0 0 0 / .3);
  position: absolute;
  right: 0;
  bottom: -44px;
}

.launch-page ul {
  list-style: none;
  display: flex;
  column-gap: 10px;
}

.launch-page .counter-timer {
  transform: skewY(-10deg);
  position: absolute;
  left: 0;
  bottom: -75px;
}

.launch-page .counter-timer > ul > li > span {
  padding-right: 5px;
}

.launch-page span.bar {
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}

.launch-page span.bar i {
  font-size: 35px;
  cursor: pointer;
}

.launch-page nav.toggle-nav {
  position: fixed;
  right: -100px;
  background: #fff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s linear;
  z-index: 333;
}

.launch-page nav.toggle-nav.active {
  right: 0px;
}

.launch-page ul.listing,
.launch-page ul.listing > li {
  display: block;
}

.launch-page ul.listing > li > a {
  font-size: 30px;
  color: #000;
  padding: 10px;
  display: block;
  text-align: center;
  transition: 0.5s linear;
}

.launch-page ul.listing > li > a:hover {
  color: #fece00;
}

.launch-page .coming-soon-inner:before {
  content: "";
  height: 2px;
  width: 60%;
  background: #000;
  position: absolute;
  top: -50px;
  right: 0;
  transform: skewY(-10deg);
}

.launch-page .coming-soon-inner:after {
  content: "";
  height: 2px;
  width: 60%;
  background: #fff;
  position: absolute;
  bottom: -80px;
  right: 0;
  transform: skewY(-10deg);
}

.launch-page .heading:before {
  content: "";
  height: 2px;
  width: 60%;
  background: #000;
  position: absolute;
  top: -50px;
  left: 0;
}

.launch-page .heading:after {
  content: "";
  height: 2px;
  width: 60%;
  background: #fff;
  position: absolute;
  bottom: -120px;
  left: 0;
}

@media only screen and (max-width: 400px) and (min-width: 200px) {
  .launch-page .heading {
    font-size: 30px;
    line-height: 40px;
    text-transform: uppercase;
    padding: 10px 20px;
  }

  .launch-page .small-heading {
    font-size: 20px;
    padding: 10px;
    bottom: -49px;
  }

  .launch-page .counter-timer li {
    font-size: 12px;
  }

  .launch-page .coming-soon-inner:before {
    top: -20px;
  }

  .launch-page .heading:before {
    top: -20px;
  }

  .launch-page .heading:after {
    bottom: -100px;
  }
}

/* @media only screen and (max-width: 767px) and (min-width: 401px) {
  .launch-page .heading {
    font-size: 40px;
    line-height: 50px;
    text-transform: uppercase;
    padding: 10px 20px;
  }

  .launch-page .small-heading {
    font-size: 25px;
    padding: 10px;
    bottom: -48px;
  }

  .launch-page .counter-timer li {
    font-size: 15px;
  }

  .launch-page .coming-soon-inner:before {
    top: -30px;
  }

  .launch-page .heading:before {
    top: -30px;
  }

  .launch-page .heading:after {
    bottom: -100px;
    }
  } */
